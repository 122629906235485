
        const $ = require('jquery');
        const css = `/* Set cursor to hand on clickable elements */
.gy-click-share,
.gy-click-screen-set,
.gy-click-login,
.gy-click-logout {
  cursor: pointer;
}

/* Hide elements that are only shown conditionally initially */
.gy-show-if-not-logged-in,
.gy-show-if-logged-out,
.gy-show-if-logged-in,
.gy-show-if-not-logged-out,
.gy-show-if-condition {
  display: none;
}

/* Show initially, hide conditionally */
.gy-hide-if-not-logged-in,
.gy-hide-if-logged-out,
.gy-hide-if-logged-in,
.gy-hide-if-not-logged-out,
.gy-hide-if-condition {
}`;
        $('head').append('<style type="text/css">' + css + '</script>');
      